import Vue from 'vue';
import VueI18n from 'vue-i18n';

export const SUPPORTED_LOCALES = ['nl', 'en', 'fr', 'de']

Vue.use(VueI18n);

const messages = {
    'nl': {
        app: {
            portal: "Mijn Omgeving",
            logout: "Uitloggen",
            buyTickets: "Bestel tickets",
            makeChoice: "Maak keuze",
            favorites: "Favorieten",
            poweredBy: "Powered by Direct Control",
        },
        nav: {
            openMenu: 'Open menu',
            myTickets: 'Mijn tickets',
            myWaitinglist: 'Mijn wachtlijst', 
            personalCredit: 'Persoonlijk tegoed',
            loyaltyCards: 'Voordeelkaarten',
            personalData: 'Persoonlijke gegevens',
            buyTickets: 'Bestel tickets',
            logOut: 'Uitloggen'
        },
        tickets: {
            title: "Mijn tickets",
        },
        history: {
            title: 'Mijn Bestelhistorie',
            noTickets: "Je hebt geen tickets voor evenementen die al geweest zijn.",
            tryAgainOrContact: "Probeer het later nog een keer of neem contact met ons op via",
            or: "of",
        },
        schedule: {
            returntickets: "Retourneren",
            tickets: "Tickets",
            refundIntroPrefix: "Het is mogelijk om je tickets voor",
            refundIntroOn: "op",
            refundIntroSuffix: "te retourneren.",
            refundCostsPrefix: "Er wordt ",
            refundCostsAmount: "€ {amount}",
            refundCostsSuffix: " aan annuleringskosten gerekend.",
            returnTicketsFor: "Retourneer hier je tickets voor",
            on: "op",
            chooseRefundOption: "Kies zelf wat er moet gebeuren met het resterende bedrag van",
            remainingAmount: "Het resterende bedrag is",
            receiveAsCredit: "Ik ontvang het bedrag graag als persoonlijk tegoed",
            receiveOnAccount: "Ik ontvang het bedrag graag terug op mijn bankrekening",
            donate: "Ik doneer het bedrag",
            contactBox: {
                title: "Neem contact op met de kassa",
                message: "Omdat je aankoop niet (volledig) met automatisch retourneerbare betaalmethoden werd betaald, kunnen we via deze weg je aanvraag om het bedrag terug te storten, niet afhandelen.",
                checkOpeningHours: "Check de openingstijden van de kassa via",
                email: "E-mail",
                phone: "Telefoon"
            },
            success: {
                title: "Het is gelukt",
                creditAdded: "Er is € {amount} aan je persoonlijke tegoed toegevoegd.",
                donated: "Je hebt € {amount} gedoneerd. Hartelijk dank!",
                refunded: "Er wordt € {amount} teruggeboekt naar je bankrekening."
            },
            ticketsNotAvailable: {
                title: "Tickets niet online beschikbaar",
                contact: "Neem voor vragen contact op via",
                notDelivered: "Je tickets zijn nog niet beschikbaar. Probeer het later nog een keer.",
                contactUs: "Neem voor vragen contact op via"
            },
            error: {
                title: "Niet gelukt",
                message: "Het is niet gelukt om deze aanvraag te verwerken.",
                contactUs: "Neem voor vragen contact op met"
            },
            modal: {
                returnTitle: "Retourneren",
                close: "Sluiten",
                cancelReservedTickets: "Annuleer hier je gereserveerde tickets voor",
                returnTicketsFor: "Retourneer hier je tickets voor",
                confirmReturn: "Ik weet zeker dat ik deze tickets wil retourneren."
            },
            chooseRefundAmount: "Kies zelf wat er moet gebeuren met het resterende bedrag van",
            remainingAmountIs: "Het resterende bedrag is",
            refundToBank: "Ik ontvang het bedrag {conditional} terug op mijn bankrekening",
            refundToBankConditional: "graag",
            noFutureTickets: "Er zijn geen tickets voor toekomstige evenementen.",
            refundTakesTime: "Dit gebeurt binnen 2 weken.",
            enterIban: "Vul je IBAN nummer in voor de terugbetaling:",
            invalidIban: "Dit is geen geldig IBAN nummer",
        },
        error: {
            fetchingData: "Het is niet gelukt de gegevens op te halen.",
        },
        modal: {
            ok: "OK",
            close: "Sluiten",
            success: "Het is gelukt",
            error: "Niet gelukt",
            errorMessage: "Er ging iets mis.",
            tryAgainOrContact: "Probeer het opnieuw of neem contact met ons op via",
            confirm: "Bevestigen",
        },
        login: {
            title: "Inloggen",
            message: "Je bent niet ingelogd. Ga naar de website om in te loggen."
        },
        event: {
            ticket: "ticket",
            tickets: "tickets"
        },
        credit: {
            title: "Persoonlijk tegoed",
            type: "Soort",
            code: "Code",
            validUntil: "Geldig tot",
            balance: "Saldo",
            credit: "tegoed",
            notAvailable: "niet meer beschikbaar",
            notYetAvailable: "nog niet beschikbaar",
            expired: "vervallen op",
            validTo: "geldig tot",
            unlimited: "onbeperkt",
            donate: "Doneren",
            refund: "Uitbetalen",
            donated: "Je hebt {amount} gedoneerd. Hartelijk dank!",
            contactCashier: "Neem contact op met de kassa",
            refundNotPossible: "Omdat je tegoedbon niet (volledig) online is betaald, kunnen we je aanvraag niet direct afhandelen.",
            checkOpeningHours: "Check de openingstijden van de kassa via",
            email: "E-mail:",
            phone: "Telefoon:",
            exchangeVoucher: "Wissel hier je tegoedbon",
            withBalance: "met saldo",
            getRefund: "in, en ontvang je geld terug.",
            refundAmount: "Er wordt {amount} teruggeboekt naar je bankrekening.",
            processingError: "Het is niet gelukt om deze aanvraag te verwerken.",
            contactForQuestions: "Neem voor vragen contact op met",
            error: "Er ging iets mis.",
            tryAgain: "Probeer het opnieuw of neem contact met ons op via",
            close: "Sluiten",
            donateNotPossible: "Het is niet mogelijk deze tegoedbon te doneren.",
            confirmDonate: "Ik weet zeker dat ik dit tegoed wil doneren",
            confirmDonateText: "Je staat op het punt je tegoed te doneren. Dit kan niet ongedaan gemaakt worden.",
            confirmRefund: "Ik weet zeker dat ik dit tegoed wil laten uitbetalen",
            confirmRefundText: "Je staat op het punt je tegoed te laten uitbetalen. Dit kan niet ongedaan gemaakt worden.",
            contact: "Neem contact op met",
            donateFinished: "Donatie voltooid",
            donateFinishedText: "Je donatie is succesvol verwerkt. Hartelijk dank!",
            errorText: "Er is iets misgegaan bij het verwerken van je aanvraag.",
            or: "of",
            refundFinished: "Uitbetaling aangevraagd",
            refundFinishedText: "Je aanvraag voor uitbetaling is succesvol verwerkt. Het bedrag wordt binnen enkele dagen op je rekening bijgeschreven.",
        },
        optins: {
            title: "Mijn voorkeuren",
            description: "Controleer en wijzig eventueel onderstaande voorkeuren. Klik vervolgens op OPSLAAN.",
            save: "Opslaan",
            back: "Terug",
            notActivated: "Het aanpassen van voorkeuren is niet geactiveerd.",
            dataChanged: "Gegevens gewijzigd",
            saveSuccess: "Je persoonlijke voorkeuren zijn succesvol opgeslagen.",
            edit: "Bewerken"
        },
        contactinfo: {
            title: "Mijn gegevens",
            salutation: "Aanhef",
            choose: "Maak keuze",
            initials: "Voorletter(s)",
            firstname: "Voornaam",
            middlename: "Tussenvoegsel",
            lastname: "Achternaam",
            street: "Straat",
            housenumber: "Huisnummer",
            addition: "Toevoeging",
            zipcode: "Postcode",
            city: "Woonplaats",
            country: "Land",
            phone: "Telefoonnummer",
            birthdate: "Geboortedatum",
            language: "Taal",
            save: "Opslaan",
            back: "Terug",
            success: "Gegevens gewijzigd",
            successMessage: "Je persoonlijke gegevens zijn succesvol opgeslagen.",
            error: "Niet gelukt",
            errorMessage: "Er ging iets mis.",
            tryAgain: "Probeer het opnieuw of neem contact met ons op via",
            required: "Dit veld is verplicht",
            fillInDetails: "Vul hier je gegevens in zodat we je kunnen bereiken.",
            name: "Naam",
            guestNumber: "Gastnummer",
            address: "Adres",
            postcode: "Postcode",
            phoneNumber: "Telefoonnummer",
        },
        languages: {
            dutch: "Nederlands",
            english: "Engels",
            french: "Frans",
            german: "Duits"
        },
        toggle: {
            current: 'Actueel',
            history: 'Historie'
        },
        loyalty: {
            title: 'Voordeelkaarten',
            type: 'Soort',
            code: 'Code',
            validity: 'Geldigheid',
            used: 'Gebruikt',
            notAvailable: 'niet meer beschikbaar',
            notYetAvailable: 'nog niet beschikbaar',
            expired: 'vervallen op',
            validUntil: 'geldig tot',
            unlimited: 'onbeperkt geldig',
            usedOf: 'van',
            times: 'keer',
            noCards: 'Er zijn geen persoonlijke voordeelkaarten gevonden.',
            error: 'Er ging iets mis.',
            tryAgainOrContact: 'Probeer het opnieuw of neem contact met ons op via'
        },
        waitinglist: {
            title: 'Mijn wachtlijst',
            cancel: 'Annuleren',
            cancelRequest: 'Annuleer hier je aanvraag voor',
            on: 'op',
            confirmCancel: 'Ik weet zeker dat ik deze aanvraag wil annuleren.',
            success: 'Het is gelukt',
            requestCancelled: 'Je aanvraag is geannuleerd.',
            error: 'Niet gelukt',
            cancelError: 'Het is niet gelukt om deze aanvraag te annuleren.',
            noRequests: 'Je hebt geen wachtlijst aanvragen voor toekomstige evenementen.',
            fetchError: 'Het is niet gelukt de gegevens op te halen.',
            tryAgainOrContact: 'Probeer het later nog een keer of neem contact met ons op via',
            or: 'of'
        },
        copy: {
            tooltip: "Klik om te kopiëren"
        },
        boolean: {
            yes: "Ja",
            no: "Nee"
        },
    },
    'en': {
        app: {
            portal: "Customer Portal",
            logout: "Log out",
            makeChoice: "Make a choice",
            buyTickets: "Buy tickets",
            favorites: "Favorites",
            poweredBy: "Powered by Direct Control",
        },
        nav: {
            openMenu: 'Open menu',
            myTickets: 'My tickets',
            myWaitinglist: 'My waitinglist',
            personalCredit: 'Personal credit', 
            loyaltyCards: 'Loyalty cards',
            personalData: 'Personal data',
            buyTickets: 'Buy tickets',
            logOut: 'Log out'
        },
        tickets: {
            title: "My tickets",
        },
        history: {
            title: 'Order history',
            noTickets: "No tickets for historical events found.",
            tryAgainOrContact: "Try again later or contact us via",
            or: "or",
        },
        schedule: {
            returntickets: "Return",
            tickets: "Tickets",
            refundIntroPrefix: "It is possible to return your tickets for",
            refundIntroOn: "on",
            refundIntroSuffix: ".",
            refundCostsPrefix: "",
            refundCostsAmount: "€ {amount}",
            refundCostsSuffix: " will be charged for cancellation.",
            returnTicketsFor: "Return your tickets for",
            on: "on",
            chooseRefundOption: "Choose what to do with the remaining amount of",
            remainingAmount: "The remaining amount is",
            receiveAsCredit: "I would like to receive the amount as personal credit",
            receiveOnAccount: "I would like to receive the amount back on my bank account",
            donate: "I will donate the amount",
            contactBox: {
                title: "Contact the cashier",
                message: "Because your purchase was not (fully) paid with automatic refundable payment methods, we cannot process your request to refund the amount via this method, as the payment and/or order has not been completed.",
                checkOpeningHours: "Check the opening hours of the cashier via",
                email: "Email",
                phone: "Phone"
            },
            success: {
                title: "Success",
                creditAdded: "€ {amount} has been added to your personal credit.",
                donated: "You have donated € {amount}. Thank you!",
                refunded: "€ {amount} will be refunded to your bank account."
            },
            ticketsNotAvailable: {
                title: "Tickets not available online",
                contact: "For questions, please contact",
                notDelivered: "Your tickets are not yet available. Please try again later.",
                contactUs: "For questions, please contact"
            },
            error: {
                title: "Failed",
                message: "The request could not be processed.",
                contactUs: "Please contact us for questions"
            },
            modal: {
                returnTitle: "Return",
                close: "Close",
                cancelReservedTickets: "Cancel your reserved tickets for",
                returnTicketsFor: "Return your tickets for",
                confirmReturn: "I confirm that I want to return these tickets."
            },
            chooseRefundAmount: "Choose what to do with the remaining amount of",
            remainingAmountIs: "The remaining amount is",
            refundToBank: "I would like to receive the amount {conditional} back on my bank account",
            refundToBankConditional: "please",
            noFutureTickets: "No tickets for future events found.",
            refundTakesTime: "This will happen within 2 weeks.",
            enterIban: "Enter your IBAN number for the refund:",
            invalidIban: "This is not a valid IBAN number",
        },
        error: {
            fetchingData: "Fetching data failed."
        },
        modal: {
            ok: "OK",
            close: "Close",
            success: "Success",
            error: "Failed",
            errorMessage: "Something went wrong.",
            tryAgainOrContact: "Please try again or contact us via",
            confirm: "Confirm",
        },
        login: {
            title: "Login",
            message: "You are not logged in. Go to the website to log in."
        },
        event: {
            ticket: "ticket",
            tickets: "tickets"
        },
        credit: {
            title: "Personal credit",
            type: "Type",
            code: "Code",
            validUntil: "Valid until",
            balance: "Balance",
            credit: "credit",
            notAvailable: "no longer available",
            notYetAvailable: "not yet available",
            expired: "expired on",
            validTo: "valid until",
            unlimited: "unlimited",
            donate: "Donate",
            refund: "Refund",
            donated: "You have donated {amount}. Thank you!",
            contactCashier: "Contact the cashier",
            refundNotPossible: "Because your voucher was not (fully) paid online, we cannot process your request directly.",
            checkOpeningHours: "Check the opening hours of the cashier via",
            email: "Email:",
            phone: "Phone:",
            exchangeVoucher: "Exchange your voucher",
            withBalance: "with balance",
            getRefund: "and receive your money back.",
            refundAmount: "{amount} will be refunded to your bank account.",
            processingError: "The request could not be processed.",
            contactForQuestions: "For questions please contact",
            error: "Something went wrong.",
            tryAgain: "Please try again or contact us via",
            close: "Close",
            donateNotPossible: "It is not possible to donate this voucher.",
            confirmDonate: "I confirm that I want to donate this credit",
            confirmDonateText: "You are about to donate your credit. This action cannot be undone.",
            confirmRefund: "I confirm that I want to receive a refund for this credit",
            confirmRefundText: "You are about to request a refund for your credit. This action cannot be undone.",
            contact: "Please contact",
            donateFinished: "Donation completed",
            donateFinishedText: "Your donation has been successfully processed. Thank you!",
            errorText: "Something went wrong while processing your request.",
            or: "or",
            refundFinished: "Refund requested",
            refundFinishedText: "Your refund request has been successfully processed. The amount will be credited to your account within a few days.",
        },
        optins: {
            title: "Preferences",
            description: "Check and modify your preferences if needed. Then click SAVE.",
            save: "Save",
            back: "Back",
            notActivated: "Modifying preferences is not activated.",
            dataChanged: "Data changed",
            saveSuccess: "Your personal preferences have been successfully saved.",
            edit: "Edit"
        },
        contactinfo: {
            title: "My details",
            salutation: "Salutation",
            choose: "Make choice",
            initials: "Initials",
            firstname: "First name",
            middlename: "Middle name",
            lastname: "Last name",
            street: "Street",
            housenumber: "House number",
            addition: "Addition",
            zipcode: "Postal code",
            city: "City",
            country: "Country",
            phone: "Phone number",
            birthdate: "Date of birth",
            language: "Language",
            save: "Save",
            back: "Back",
            success: "Details changed",
            successMessage: "Your personal details have been successfully saved.",
            error: "Failed",
            errorMessage: "Something went wrong.",
            tryAgain: "Please try again or contact us via",
            required: "This field is required",
            fillInDetails: "Please fill in your details so we can contact you.",
            name: "Name",
            guestNumber: "Guest number",
            address: "Address",
            postcode: "Postal code",
            phoneNumber: "Phone number",
        },
        languages: {
            dutch: "Dutch",
            english: "English",
            french: "French",
            german: "German"
        },
        toggle: {
            current: 'Current',
            history: 'History'
        },
        loyalty: {
            title: 'Loyalty Cards',
            type: 'Type',
            code: 'Code',
            validity: 'Validity',
            used: 'Used',
            notAvailable: 'no longer available',
            notYetAvailable: 'not yet available',
            expired: 'expired on',
            validUntil: 'valid until',
            unlimited: 'unlimited validity',
            usedOf: 'of',
            times: 'times',
            noCards: 'No personal loyalty cards found.',
            error: 'Something went wrong.',
            tryAgainOrContact: 'Please try again or contact us via'
        },
        waitinglist: {
            title: 'My waitinglist',
            cancel: 'Cancel',
            cancelRequest: 'Cancel your request for',
            on: 'on',
            confirmCancel: 'I confirm that I want to cancel this request.',
            success: 'Success',
            requestCancelled: 'Your request has been cancelled.',
            error: 'Failed',
            cancelError: 'Failed to cancel this request.',
            noRequests: 'You have no waitinglist requests for future events.',
            fetchError: 'Failed to fetch the data.',
            tryAgainOrContact: 'Please try again later or contact us via',
            or: 'or'
        },
        copy: {
            tooltip: "Click to copy"
        },
        boolean: {
            yes: "Yes",
            no: "No"
        },
    },
    'fr': {
        app: {
            portal: "Mon Environnement",
            logout: "Déconnexion",
            buyTickets: "Acheter des billets",
            makeChoice: "Faites un choix",
            favorites: "Favoris",
            poweredBy: "Propulsé par Direct Control",
        },
        nav: {
            openMenu: 'Open menu',
            myTickets: 'Mes tickets',
            myWaitinglist: 'Ma liste d\'attente',
            personalCredit: 'Crédit personnel', 
            loyaltyCards: 'Cartes fidélité',
            personalData: 'Données personnelles',
            buyTickets: 'Acheter des tickets',
            logOut: 'Se déconnecter'
        },
        tickets: {
            title: "Mes tickets",
        },
        history: {
            title: 'Historique des commandes',
            noTickets: "Aucun ticket trouvé pour les événements passés.",
            tryAgainOrContact: "Réessayez plus tard ou contactez-nous via",
            or: "ou",
        },
        schedule: {
            returntickets: "Retour",
            tickets: "Tickets",
            refundIntroPrefix: "Il est possible de retourner vos tickets pour",
            refundIntroOn: "sur",
            refundIntroSuffix: ".",
            refundCostsPrefix: "",
            refundCostsAmount: "€ {amount}",
            refundCostsSuffix: " seront facturés pour annulation.",
            returnTicketsFor: "Retourner vos tickets pour",
            on: "sur",
            chooseRefundOption: "Choisir ce que faire avec le restant de",
            remainingAmount: "Le restant est de",
            receiveAsCredit: "Je souhaite recevoir le montant en crédit personnel",
            receiveOnAccount: "Je souhaite recevoir le montant sur mon compte bancaire",
            donate: "Je vais donner le montant",
            contactBox: {
                title: "Contacter le caissier",
                message: "Parce que votre achat n'a pas été (totalement) payé avec des méthodes de paiement automatique réversibles, nous ne pouvons pas traiter votre demande de remboursement du montant via cette méthode, car le paiement et/ou la commande n'a pas été terminé.",
                checkOpeningHours: "Vérifier les heures d'ouverture du caissier via",
                email: "E-mail",
                phone: "Téléphone"
            },
            success: {
                title: "Succès",
                creditAdded: "€ {amount} a été ajouté à votre crédit personnel.",
                donated: "Vous avez fait un don de € {amount}. Merci !",
                refunded: "€ {amount} sera remboursé sur votre compte bancaire."
            },
            ticketsNotAvailable: {
                title: "Billets non disponibles en ligne",
                contact: "Pour toute question, veuillez contacter",
                notDelivered: "Vos billets ne sont pas encore disponibles. Veuillez réessayer plus tard.",
                contactUs: "Pour toute question, veuillez contacter"
            },
            error: {
                title: "Échec",
                message: "La demande n'a pas pu être traitée.",
                contactUs: "Veuillez nous contacter pour des questions"
            },
            modal: {
                returnTitle: "Retour",
                close: "Fermer",
                cancelReservedTickets: "Annulez vos billets réservés pour",
                returnTicketsFor: "Retournez vos billets pour",
                confirmReturn: "Je confirme vouloir retourner ces billets."
            },
            chooseRefundAmount: "Choisissez ce qu'il faut faire avec le montant restant de",
            remainingAmountIs: "Le montant restant est",
            refundToBank: "Je souhaite recevoir le montant {conditional} sur mon compte bancaire",
            refundToBankConditional: "s'il vous plaît",
            noFutureTickets: "Aucun billet trouvé pour les événements futurs.",
            refundTakesTime: "Cela se fera dans les 2 semaines.",
            enterIban: "Entrez votre numéro IBAN pour le remboursement :",
            invalidIban: "Ce n'est pas un numéro IBAN valide",
        },
        error: {
            fetchingData: "La récupération des données a échoué."
        },
        modal: {
            ok: "OK",
            close: "Fermer",
            success: "Succès",
            error: "Erreur",
            errorMessage: "Quelque chose s'est mal passé.",
            tryAgainOrContact: "Veuillez réessayer ou nous contacter via",
            confirm: "Confirmer",
        },
        login: {
            title: "Connexion",
            message: "Vous n'êtes pas connecté. Allez sur le site du théâtre. Là, vous trouverez la bonne référence pour My Environment."
        },
        event: {
            ticket: "billet",
            tickets: "billets"
        },
        credit: {
            title: "Crédit personnel",
            type: "Type",
            code: "Code",
            validUntil: "Valable jusqu'au",
            balance: "Solde",
            credit: "crédit",
            notAvailable: "plus disponible",
            notYetAvailable: "pas encore disponible",
            expired: "expiré le",
            validTo: "valable jusqu'au",
            unlimited: "illimité",
            donate: "Faire un don",
            refund: "Rembourser",
            donated: "Vous avez fait un don de {amount}. Merci !",
            contactCashier: "Contacter le caissier",
            refundNotPossible: "Parce que votre bon de réduction n'a pas été payé en ligne (intégralement), nous ne pouvons pas traiter votre demande directement.",
            checkOpeningHours: "Vérifier les heures d'ouverture du caissier via",
            email: "E-mail :",
            phone: "Téléphone :",
            exchangeVoucher: "Échanger votre bon de réduction",
            withBalance: "avec solde",
            getRefund: "et recevoir votre argent de retour.",
            refundAmount: "{amount} sera remboursé sur votre compte bancaire.",
            processingError: "La demande n'a pas pu être traitée.",
            contactForQuestions: "Pour toute question, veuillez nous contacter",
            error: "Une erreur s'est produite.",
            tryAgain: "Veuillez réessayer ou nous contacter via",
            close: "Fermer",
            donateNotPossible: "Il n'est pas possible de faire un don de ce bon.",
            confirmDonate: "Je confirme vouloir faire don de ce crédit",
            confirmDonateText: "Vous êtes sur le point de faire don de votre crédit. Cette action ne peut pas être annulée.",
            confirmRefund: "Je confirme vouloir être remboursé de ce crédit",
            confirmRefundText: "Vous êtes sur le point de demander un remboursement de votre crédit. Cette action ne peut pas être annulée.",
            contact: "Veuillez contacter",
            donateFinished: "Don effectué",
            donateFinishedText: "Votre don a été traité avec succès. Merci !",
            errorText: "Une erreur s'est produite lors du traitement de votre demande.",
            or: "ou",
            refundFinished: "Remboursement demandé",
            refundFinishedText: "Votre demande de remboursement a été traitée avec succès. Le montant sera crédité sur votre compte dans quelques jours.",
        },
        optins: {
            title: "Préférences",
            description: "Vérifier et modifier vos préférences si nécessaire. Ensuite, cliquez sur ENREGISTRER.",
            save: "Enregistrer",
            back: "Retour",
            notActivated: "Modification des préférences non activée.",
            dataChanged: "Données modifiées",
            saveSuccess: "Vos préférences personnelles ont été enregistrées avec succès.",
            edit: "Modifier"
        },
        contactinfo: {
            title: "Mes informations",
            salutation: "Salutation",
            choose: "Choisir",
            initials: "Initiales",
            firstname: "Prénom",
            middlename: "Deuxième prénom",
            lastname: "Nom de famille",
            street: "Rue",
            housenumber: "Numéro de maison",
            addition: "Ajout",
            zipcode: "Code postal",
            city: "Ville",
            country: "Pays",
            phone: "Numéro de téléphone",
            birthdate: "Date de naissance",
            language: "Langue",
            save: "Enregistrer",
            back: "Retour",
            success: "Informations mises à jour",
            successMessage: "Vos informations personnelles ont été enregistrées avec succès.",
            error: "Échec",
            errorMessage: "Quelque chose s'est mal passé.",
            tryAgain: "Veuillez réessayer ou nous contacter via",
            required: "Ce champ est obligatoire",
            fillInDetails: "Veuillez remplir vos coordonnées pour que nous puissions vous contacter.",
            name: "Nom",
            guestNumber: "Numéro d'invité",
            address: "Adresse",
            postcode: "Code postal",
            phoneNumber: "Numéro de téléphone",
        },
        languages: {
            dutch: "Néerlandais",
            english: "Anglais",
            french: "Français",
            german: "Allemand"
        },
        toggle: {
            current: 'Actuel',
            history: 'Historique'
        },
        loyalty: {
            title: 'Cartes de Fidélité',
            type: 'Type',
            code: 'Code',
            validity: 'Validité',
            used: 'Utilisé',
            notAvailable: 'plus disponible',
            notYetAvailable: 'pas encore disponible',
            expired: 'expiré le',
            validUntil: 'valable jusqu\'au',
            unlimited: 'validité illimitée',
            usedOf: 'sur',
            times: 'fois',
            noCards: 'Aucune carte de fidélité personnelle trouvée.',
            error: 'Une erreur s\'est produite.',
            tryAgainOrContact: 'Veuillez réessayer ou nous contacter via'
        },
        waitinglist: {
            title: 'Ma liste d\'attente',
            cancel: 'Annuler',
            cancelRequest: 'Annulez votre demande pour',
            on: 'le',
            confirmCancel: 'Je confirme vouloir annuler cette demande.',
            success: 'Succès',
            requestCancelled: 'Votre demande a été annulée.',
            error: 'Échec',
            cancelError: 'Impossible d\'annuler cette demande.',
            noRequests: 'Vous n\'avez pas de demandes en liste d\'attente pour les événements futurs.',
            fetchError: 'Impossible de récupérer les données.',
            tryAgainOrContact: 'Veuillez réessayer plus tard ou nous contacter via',
            or: 'ou'
        },
        copy: {
            tooltip: "Cliquez pour copier"
        },
        boolean: {
            yes: "Oui",
            no: "Non"
        },
    },
    'de': {
        app: {
            portal: "Mein Umgebung",
            logout: "Ausloggen",
            buyTickets: "Tickets kaufen",
            makeChoice: "Treffen Sie eine Wahl",
            favorites: "Favoriten",
            poweredBy: "Bereitgestellt von Direct Control",
        },
        nav: {
            openMenu: 'Menü öffnen',
            myTickets: 'Meine Tickets',
            myWaitinglist: 'Wartenliste', 
            personalCredit: 'Persönliches Guthaben',
            loyaltyCards: 'Treuekarten',
            personalData: 'Persönliche Daten',
            buyTickets: 'Tickets kaufen',
            logOut: 'Ausloggen'
        },
        tickets: {
            title: "Meine Tickets",
        },
        history: {
            title: 'Bestelhistorie',
            noTickets: "Keine Tickets für vergangene Veranstaltungen gefunden.",
            tryAgainOrContact: "Versuchen Sie es später erneut oder kontaktieren Sie uns über",
            or: "oder",
        },
        schedule: {
            returntickets: "Rückgabe",
            tickets: "Tickets",
            refundIntroPrefix: "Sie können Ihre Tickets für diese Veranstaltung zurückgeben.",
            refundIntroOn: "auf",
            refundIntroSuffix: ".",
            refundCostsPrefix: "Es werden ",
            refundCostsAmount: "{amount} €",
            refundCostsSuffix: " für die Stornierung berechnet.",
            returnTicketsFor: "Rückgabe Ihrer Tickets für",
            on: "auf",
            chooseRefundOption: "Wählen Sie, was Sie mit dem Restbetrag von",
            remainingAmount: "Der Restbetrag ist",
            receiveAsCredit: "Ich möchte den Betrag gerne als persönliches Guthaben erhalten",
            receiveOnAccount: "Ich möchte den Betrag auf mein Bankkonto zurückerhalten",
            donate: "Ich werde den Betrag spenden",
            contactBox: {
                title: "Kontaktieren Sie den Kassierer",
                message: "Da Ihre Bestellung nicht (vollständig) mit automatisch rückzahlbaren Zahlungsmethoden bezahlt wurde, können wir Ihre Anfrage zur Rückerstattung des Betrags über diese Methode nicht verarbeiten, da der Zahlungs- und/oder Bestellungsprozess noch nicht abgeschlossen wurde.",
                checkOpeningHours: "Öffnungszeiten des Kassierers überprüfen",
                email: "E-Mail",
                phone: "Telefon"
            },
            success: {
                title: "Erfolg",
                creditAdded: "€ {amount} wurden zu Ihrem persönlichen Guthaben hinzugefügt.",
                donated: "Sie haben € {amount} gespendet. Vielen Dank!",
                refunded: "€ {amount} werden auf Ihr Bankkonto zurückgebucht."
            },
            ticketsNotAvailable: {
                title: "Tickets nicht online verfügbar",
                contact: "Bei Fragen wenden Sie sich bitte an",
                notDelivered: "Ihre Tickets sind noch nicht verfügbar. Bitte versuchen Sie es später erneut.",
                contactUs: "Bei Fragen wenden Sie sich bitte an"
            },
            error: {
                title: "Fehler",
                message: "Die Anfrage konnte nicht verarbeitet werden.",
                contactUs: "Bitte kontaktieren Sie uns für Fragen"
            },
            modal: {
                returnTitle: "Rückgabe",
                close: "Schließen",
                cancelReservedTickets: "Stornieren Sie Ihre reservierten Tickets für",
                returnTicketsFor: "Geben Sie Ihre Tickets zurück für",
                confirmReturn: "Ich bestätige, dass ich diese Tickets zurückgeben möchte."
            },
            chooseRefundAmount: "Wählen Sie, was mit dem Restbetrag geschehen soll von",
            remainingAmountIs: "Der Restbetrag beträgt",
            refundToBank: "Ich möchte den Betrag {conditional} auf mein Bankkonto zurückerhalten",
            refundToBankConditional: "bitte",
            noFutureTickets: "Keine Tickets für zukünftige Veranstaltungen gefunden.",
            refundTakesTime: "Dies geschieht innerhalb von 2 Wochen.",
            enterIban: "Geben Sie Ihre IBAN für die Rückerstattung ein:",
            invalidIban: "Dies ist keine gültige IBAN",
        },
        error: {
            fetchingData: "Die Daten konnten nicht abgerufen werden."
        },
        modal: {
            ok: "OK",
            close: "Schließen",
            success: "Erfolg",
            error: "Fehler",
            errorMessage: "Etwas ist schief gelaufen.",
            tryAgainOrContact: "Bitte versuchen Sie es erneut oder kontaktieren Sie uns über",
            confirm: "Bestätigen",
        },
        login: {
            title: "Anmelden",
            message: "Sie sind nicht eingeloggt. Gehen Sie auf die Theaterwebsite. Dort finden Sie die richtige Referenz für My Environment."
        },
        event: {
            ticket: "Ticket",
            tickets: "Tickets"
        },
        credit: {
            title: "Persönliches Guthaben",
            type: "Typ",
            code: "Code",
            validUntil: "Gültig bis",
            balance: "Saldo",
            credit: "Guthaben",
            notAvailable: "nicht mehr verfügbar",
            notYetAvailable: "noch nicht verfügbar",
            expired: "abgelaufen am",
            validTo: "gültig bis",
            unlimited: "unbegrenzt",
            donate: "Spenden",
            refund: "Erstatten",
            donated: "Sie haben {amount} gespendet. Vielen Dank!",
            contactCashier: "Kontaktieren Sie den Kassierer",
            refundNotPossible: "Da Ihre Gutschein nicht (vollständig) online bezahlt wurde, können wir Ihre Anfrage nicht direkt bearbeiten.",
            checkOpeningHours: "Öffnungszeiten des Kassierers überprüfen",
            email: "E-Mail:",
            phone: "Telefon:",
            exchangeVoucher: "Gutschein tauschen",
            withBalance: "mit Guthaben",
            getRefund: "und erhalten Sie Ihr Geld zurück.",
            refundAmount: "{amount} wird auf Ihr Bankkonto zurückgebucht.",
            processingError: "Die Anfrage konnte nicht verarbeitet werden.",
            contactForQuestions: "Für Fragen bitte kontaktieren Sie",
            error: "Etwas ist schief gelaufen.",
            tryAgain: "Bitte versuchen Sie es erneut oder kontaktieren Sie uns über",
            close: "Schließen",
            donateNotPossible: "Es ist nicht möglich, diesen Gutschein zu spenden.",
            confirmDonate: "Ich bestätige, dass ich dieses Guthaben spenden möchte",
            confirmDonateText: "Sie sind dabei, Ihr Guthaben zu spenden. Diese Aktion kann nicht rückgängig gemacht werden.",
            confirmRefund: "Ich bestätige, dass ich dieses Guthaben erstattet haben möchte",
            confirmRefundText: "Sie sind dabei, eine Erstattung Ihres Guthabens zu beantragen. Diese Aktion kann nicht rückgängig gemacht werden.",
            contact: "Bitte kontaktieren Sie",
            donateFinished: "Spende abgeschlossen",
            donateFinishedText: "Ihre Spende wurde erfolgreich verarbeitet. Vielen Dank!",
            errorText: "Bei der Verarbeitung Ihrer Anfrage ist ein Fehler aufgetreten.",
            or: "oder",
            refundFinished: "Erstattung beantragt",
            refundFinishedText: "Ihre Erstattungsanfrage wurde erfolgreich verarbeitet. Der Betrag wird innerhalb weniger Tage Ihrem Konto gutgeschrieben.",
        },
        optins: {
            title: "Einstellungen",
            description: "Überprüfen und ändern Sie Ihre Einstellungen, wenn nötig. Klicken Sie dann auf SPEICHERN.",
            save: "Speichern",
            back: "Zurück",
            notActivated: "Änderungen der Einstellungen sind nicht aktiviert.",
            dataChanged: "Daten geändert",
            saveSuccess: "Ihre persönlichen Einstellungen wurden erfolgreich gespeichert.",
            edit: "Bearbeiten"
        },
        contactinfo: {
            title: "Meine Daten",
            salutation: "Anrede",
            choose: "Wählen",
            initials: "Initialen",
            firstname: "Vorname",
            middlename: "Mittelname",
            lastname: "Nachname",
            street: "Straße",
            housenumber: "Hausnummer",
            addition: "Zusatz",
            zipcode: "Postleitzahl",
            city: "Stadt",
            country: "Land",
            phone: "Telefonnummer",
            birthdate: "Geburtsdatum",
            language: "Sprache",
            save: "Speichern",
            back: "Zurück",
            success: "Daten geändert",
            successMessage: "Ihre persönlichen Daten wurden erfolgreich gespeichert.",
            error: "Fehler",
            errorMessage: "Etwas ist schief gelaufen.",
            tryAgain: "Bitte versuchen Sie es erneut oder kontaktieren Sie uns über",
            required: "Dieses Feld ist erforderlich",
            fillInDetails: "Bitte füllen Sie Ihre Daten aus, damit wir Sie kontaktieren können.",
            name: "Name",
            guestNumber: "Gastnummer",
            address: "Adresse",
            postcode: "Postleitzahl",
            phoneNumber: "Telefonnummer",
        },
        languages: {
            dutch: "Niederländisch",
            english: "Englisch",
            french: "Französisch",
            german: "Deutsch"
        },
        toggle: {
            current: 'Aktuell',
            history: 'Historie'
        },
        loyalty: {
            title: 'Treuekarten',
            type: 'Typ',
            code: 'Code',
            validity: 'Gültigkeit',
            used: 'Verwendet',
            notAvailable: 'nicht mehr verfügbar',
            notYetAvailable: 'noch nicht verfügbar',
            expired: 'abgelaufen am',
            validUntil: 'gültig bis',
            unlimited: 'unbegrenzte Gültigkeit',
            usedOf: 'von',
            times: 'mal',
            noCards: 'Keine persönlichen Treuekarten gefunden.',
            error: 'Etwas ist schief gelaufen.',
            tryAgainOrContact: 'Bitte versuchen Sie es erneut oder kontaktieren Sie uns über'
        },
        waitinglist: {
            title: 'Meine Warteliste',
            cancel: 'Stornieren',
            cancelRequest: 'Stornieren Sie Ihre Anfrage für',
            on: 'am',
            confirmCancel: 'Ich bestätige, dass ich diese Anfrage stornieren möchte.',
            success: 'Erfolg',
            requestCancelled: 'Ihre Anfrage wurde storniert.',
            error: 'Fehler',
            cancelError: 'Diese Anfrage konnte nicht storniert werden.',
            noRequests: 'Sie haben keine Wartelistenanfragen für zukünftige Veranstaltungen.',
            fetchError: 'Die Daten konnten nicht abgerufen werden.',
            tryAgainOrContact: 'Bitte versuchen Sie es später erneut oder kontaktieren Sie uns über',
            or: 'oder'
        },
        copy: {
            tooltip: "Klicken zum Kopieren"
        },
        boolean: {
            yes: "Ja",
            no: "Nein"
        },
    },
};

const i18n = new VueI18n({
    locale: 'nl',
    fallbackLocale: 'en',
    messages,
});

export function mergeAccountTranslations(config) {
    if (!config?.nav) return;

    ['en', 'nl', 'fr', 'de'].forEach(locale => {
        const navTranslations = {};
        
        Object.keys(config.nav).forEach(key => {
            if (config.nav[key]?.[locale]) {
                navTranslations[key] = config.nav[key][locale];
            }
        });

        if (Object.keys(navTranslations).length > 0) {
            i18n.mergeLocaleMessage(locale, {
                nav: navTranslations
            });
        }
    });
}

export default i18n;