const axios = require('axios')

export async function getContactInfo() {
    const response = await axios.get('/api/contact/info')
    if (!response.status === 200) {
        const data = await response.data
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }    
    return await response.data
}

export async function getOptins() {
    const response = await axios.get('/api/optins')
    if (!response.status === 200) {
        const data = await response.data
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }    
    return await response.data    
}

export async function getConfig() {
    const response = await axios.get('/api/contact/config')
    if (!response.status === 200) {
        const data = await response.data
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }    
    return await response.data
}

export async function getCustomFields() {
    const response = await axios.get('/api/contact/customfields')
    if (!response.status === 200) {
        const data = await response.data
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }    
    return await response.data
}

export async function updateContactInfo(payload) {
    const response = await axios.put('/api/contact', payload)
    if (!response) {
        return {}
    }
    return response.data;
}

export async function updateOptins(payload) {
    const response = await axios.put('/api/optins', payload)
    if (!response) {
        return {}
    }
    return response.data;
}