import i18n from '@/plugins/i18n'
import { getTranslatedString } from '@/utils/i18n'

export default {
    computed: {
        lang() {
            return i18n.locale
        }
    },
    methods: {
        readConfig(prop) {
            if (!this.$store.state.config) {
                return ""
            }
            if (!this.$store.state.config[prop]) {
                return ""
            }
            return getTranslatedString(this.$store.state.config[prop])
        }
    }
} 