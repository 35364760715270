<template>
  <div v-if="!$auth.loading">
    <div id="app" class="flex flex-col min-h-screen" :class="config.class">
      <header>
        <nav class="w-full py-4 bg-secondary shadow hidden sm:block">
            <div class="w-full flex justify-end">
                <ul class="flex items-center justify-between font-bold text-sm text-secondary-contrast uppercase no-underline">
                    <li class="px-4 flex items-center" v-if="config.favoritesUrl">
                        <a :target="config.urlTarget" :title="$t('app.favorites')" :href="config.favoritesUrl" class="hover:opacity-40 flex items-center">
                          <font-awesome-icon icon="heart" size="2x"/>
                        </a>
                    </li>  
                    <li class="px-4 flex items-center" v-if="config.salesUrl">
                        <a :target="config.urlTarget" :title="$t('app.buyTickets')" :href="config.salesUrl" class="hover:opacity-40 flex items-center">
                          <svg id="calendar_icon" class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 2.25h.75a3 3 0 013 3v14.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V5.25a3 3 0 013-3H6.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z" clip-rule="evenodd" />
                          </svg>
                        </a>
                    </li>
                    <li class="px-4 flex items-center" v-if="config.pluginUrl">
                      <Basket/>
                    </li>
                    <template v-if="$auth.isAuthenticated">
                      <li class="px-4" data-private>
                        <router-link v-if="contactLabel && config.loyalty.active"
                          to="/loyalty"
                          class="hover:opacity-40 hover:underline px-2">
                          <span class="inline-flex items-center rounded-md text-primary px-2 py-1 text-xs font-medium bg-white ring-1 ring-inset ring-gray-500/10">
                            {{ contactLabel }}
                          </span>
                        </router-link>
                        <span v-else-if="contactLabel" class="inline-flex items-center rounded-md text-primary px-2 py-1 text-xs font-medium bg-white ring-1 ring-inset ring-gray-500/10">
                            {{ contactLabel }}
                        </span>
                        
                        <router-link to="/info" class="hover:opacity-40 px-2">
                          {{ fullName }}
                        </router-link>
                      </li>
                      <li v-if="showLanguageSwitcher" class="px-4 flex items-center">
                        <select 
                          v-model="currentLocale" 
                          class="bg-transparent text-secondary-contrast hover:opacity-40 cursor-pointer font-bold px-2"
                          @change="handleLocaleChange">
                          <option v-for="lang in config.languages" :key="lang" :value="lang" class="text-gray-900 bg-white">
                            {{ lang.toUpperCase() }}
                          </option>
                        </select>
                      </li>
                      <li class="px-4 flex items-center">
                        <router-link class="hover:opacity-40 hover:underline px-2" to="/logout">{{ $t('app.logout') }}</router-link>
                      </li>
                    </template>
                    <li v-else>
                        <a class="hover:opacity-40 hover:underline px-4" href="/login">{{ $t('app.portal') }}</a>
                    </li>
                </ul>
            </div>
        </nav>

        <div class="w-full lg:w-5/6 xl:w-3/4 2xl:w-1/2 container mx-auto hidden sm:block">
          <div class="flex justify-between py-12">
              <div>
                  <p class="font-bold text-title text-5xl">
                      {{ readConfig("title") }}
                  </p>
                  <p class="text-lg text-black" v-html="readConfig('subtitle')">
                  </p>
              </div>
              <div v-if="config.image">
                  <a v-if="config.salesUrl" :href="config.salesUrl" :target="config.urlTarget" rel="noopener noreferrer">
                      <img :src="config.image" class="max-w-lg" style="max-width: 400px; max-height: 150px;" />
                  </a>
                  <img v-else :src="config.image" class="max-w-lg" style="max-width: 400px; max-height: 150px;" />
              </div>
          </div>
        </div>
        <NavBar/>
      </header>

      <main class="flex-grow">
        <router-view/>
      </main>

      <footer class="w-full border-t bg-white bg-panel mt-12">
          <div class="w-full container mx-auto flex flex-col items-center">
              <div class="flex flex-col md:flex-row text-center md:text-left md:justify-between py-6">
                  <a v-for="link of config.footer" v-bind:key="link.url" :href="link.url" :target="config.urlTarget" class="px-3">
                    {{ link.name[lang] }}
                  </a>
              </div>
              <div class="pb-6" v-if="config.poweredBy">
                <a href="https://www.linkedin.com/company/direct-control-nl" target="_blank">
                  {{ $t('app.poweredBy') }}
                </a>
              </div>
          </div>
      </footer>
    </div>
  </div>
  <div v-else class="flex justify-center items-center h-screen">
    <font-awesome-icon icon="spinner" spin="spin" size="3x" class="text-primary"/>
  </div>
</template>

<script>
import { useFavicon } from '@vueuse/core'
import { mapGetters } from 'vuex'
import Basket from '@/components/Basket.vue'
import NavBar from '@/components/NavBar.vue'
import { SUPPORTED_LOCALES } from '@/plugins/i18n'
import axios from 'axios'

export default {
  title: "app.portal",
  components: {
    Basket,
    NavBar
  },
  data() {
    return {
      currentLocale: this.$i18n.locale
    }
  },
  computed: {
      ...mapGetters(['config', 'fullName', 'contactLabel']),
      showLanguageSwitcher() {
        return this.config.languages && this.config.languages.length > 1
      }
  },
  watch: {
    '$route': {
      immediate: true,
      handler(to) {
        if (to.query.l) {
          console.log('Route detected locale:', to.query.l)
          if (SUPPORTED_LOCALES.includes(to.query.l)) {
            this.$i18n.locale = to.query.l
            localStorage.setItem('userLocale', to.query.l)
            // Sync with backend
            axios.post('/auth/locale', { locale: to.query.l })
              .catch(error => console.warn('Failed to sync locale with backend:', error))
          }
        }
      }
    },
    config: {
      handler() {
        if (this.config.locale && !localStorage.getItem('userLocale')) {
          console.log('Setting config locale:', this.config.locale)
          this.$i18n.locale = this.config.locale
        }
        
        const icon = useFavicon()
        if (this.config.favicon) {
          icon.value = this.config.favicon
        }          
      },
      deep: true,
    },
  },    
  async created () {
    this.$store.dispatch('resetSchedule')
    this.$store.dispatch('resetHistory')
    this.$store.dispatch('resetWaitinglist')
    this.$store.dispatch('resetCredit')
    this.$store.dispatch('resetLoyalty')
    this.$store.dispatch('resetContactInfo')
    this.$store.dispatch('resetOptins')
    const savedLocale = localStorage.getItem('userLocale')
    if (savedLocale && SUPPORTED_LOCALES.includes(savedLocale)) {
      this.$i18n.locale = savedLocale
    }
  },
  methods: {
    handleLocaleChange() {
      this.$i18n.locale = this.currentLocale
      localStorage.setItem('userLocale', this.currentLocale)
      // Sync with backend
      axios.post('/auth/locale', { locale: this.currentLocale })
        .catch(error => console.warn('Failed to sync locale with backend:', error))
    }
  },
}
</script>

<style lang="scss">
  html { overflow-y: scroll; }

  .nav-wrapper {
    @apply bg-primary;
  }

  select option {
    background-color: white;
    color: #111827; /* text-gray-900 */
    padding: 0.5rem;
  }

  #heart_icon {
    // ... existing code ...
  }
</style>