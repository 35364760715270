<template>
  <nav class="w-full py-4 border-t border-b bg-mainnav">
      <div class="block sm:hidden">
          <a
              href="#"
              class="md:hidden text-base font-bold uppercase text-center flex justify-center items-center"
              @click="open = !open"
          >
              <div class="mt-1">
                {{ config.accountname }}
              </div>
              <button class="text-gray-500 w-10 h-10 relative focus:outline-none">
                  <span class="sr-only">{{ $t('nav.openMenu') }}</span>
                  <div class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <span aria-hidden="true" class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out" :class="{'rotate-45': open,' -translate-y-1.5': !open }"></span>
                      <span aria-hidden="true" class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out" :class="{'opacity-0': open }"></span>
                      <span aria-hidden="true" class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out" :class="{'-rotate-45': open, ' translate-y-1.5': !open}"></span>
                  </div>
              </button>
          </a>
      </div>
      <div :class="open ? 'block': 'hidden'" class="w-full flex-grow sm:flex sm:items-center sm:w-auto">
          <div class="w-full container mx-auto flex flex-col sm:flex-row items-center justify-center text-sm font-bold uppercase mt-0 px-6 py-2">
              <template v-if="$auth.isAuthenticated">
                <!-- Render het juiste component gebaseerd op item type -->
                <component 
                  v-for="item in orderedNavItems"
                  :is="item.type === 'external' ? 'a' : 'router-link'"
                  :key="item.key"
                  :to="item.route"
                  :href="item.type === 'external' ? item.url() : undefined"
                  :target="item.type === 'external' ? config.urlTarget : undefined"
                  class="hover:bg-mainnav-hover rounded py-2 px-4 mx-2"
                >
                  {{ $t(item.translation) }}
                </component>
                
                <router-link class="hover:bg-mainnav-hover rounded py-2 px-4 mx-2 block sm:hidden" to="/logout">
                  {{ $t('nav.logOut') }}
                </router-link>
              </template>
          </div>
      </div>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavBar',
  data() {
    return {
      open: false,
      navItems: {
        schedule: {
          route: '/schedule',
          translation: 'nav.myTickets',
          condition: () => true
        },
        waitinglist: {
          route: '/waitinglist',
          translation: 'nav.myWaitinglist',
          condition: () => this.config.waitinglist.active && this.$auth.contactinfo.hasWaitinglist
        },
        credit: {
          route: '/credit',
          translation: 'nav.personalCredit',
          condition: () => this.config.credit.active
        },
        loyalty: {
          route: '/loyalty',
          translation: 'nav.loyaltyCards',
          condition: () => this.config.loyalty.active && this.$auth.contactinfo.hasLoyalty
        },
        info: {
          route: '/info',
          translation: 'nav.personalData',
          condition: () => true
        },
        sales: {
          type: 'external',
          url: () => this.config.salesUrl,
          translation: 'nav.buyTickets',
          condition: () => this.config.salesUrl
        }
      }
    }
  },
  computed: {
    ...mapGetters(["config"]),
    orderedNavItems() {
      const navOrder = this.config?.nav_order || ['schedule', 'waitinglist', 'credit', 'loyalty', 'info', 'sales']
      return navOrder
        .map(key => ({ key, ...this.navItems[key] }))
        .filter(item => item.condition?.())
    }
  }
}
</script>
<style>
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
a:hover {
  cursor: pointer;
}
#nav a.router-link-exact-active {
  color: #FFCC00;
}
</style>