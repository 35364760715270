import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "../auth/authGuard"
import { getInstance } from "../auth/index"
import store from '../store'
import { trackPageview } from '../services/TrackService'
import i18n from '@/plugins/i18n'
import { SUPPORTED_LOCALES } from '@/plugins/i18n'

Vue.use(VueRouter)

const routes = [
  { 
    path: '/', 
    name: 'Home',
  },
  {
    path: '/info',
    name: 'Persoonlijke gegevens',
    // route level code-splitting
    // this generates a separate chunk (....[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Personal.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/contactinfo',
    name: 'Persoonlijke gegevens bewerken',
    // route level code-splitting
    // this generates a separate chunk (....[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Contactinfo.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/contactinfo2',
    name: 'Persoonlijke gegevens bewerken 2',
    // route level code-splitting
    // this generates a separate chunk (....[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ContactinfoDynamic.vue'),
    beforeEnter: authGuard,
  },  
  {
    path: '/optins',
    name: 'Voorkeuren bewerken',
    // route level code-splitting
    // this generates a separate chunk (....[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Optins.vue'),
    beforeEnter: authGuard,
  },    
  {
    path: '/schedule',
    name: 'Tickets',
    component: () => import('../views/Tickets.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/waitinglist',
    name: 'Wachtlijst',
    component: () => import('../views/Waitinglist.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/credit',
    name: 'Tegoeden',
    component: () => import('../views/Credit.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/loyalty',
    name: 'Voordelen',
    component: () => import('../views/Loyalty.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/login',
    name: 'Start',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/logout',
    name: 'Uitloggen',
    async beforeEnter() {
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Locale handling
const handleLocaleChange = (to, next) => {
  const locale = to.query.l
  if (locale) {
    if (SUPPORTED_LOCALES.includes(locale)) {
      i18n.locale = locale
      localStorage.setItem('userLocale', locale)
      
      const query = {...to.query}
      delete query.l
      return next({
        ...to,
        query
      })
    }
  }
  
  const savedLocale = localStorage.getItem('userLocale')
  if (savedLocale && SUPPORTED_LOCALES.includes(savedLocale)) {
    i18n.locale = savedLocale
  }
  return false
}

// Page tracking
const handlePageTracking = (to) => {
  if (to.name !== "Uitloggen" && to.name !== "Start") {
    try {
      trackPageview(to.name)
    }
    catch(err) {
      console.log(`Did not track pageview '${to.name}'`)
    }
  }
}

// Logout handling
const handleLogout = async (to, next) => {
  if (to.name === "Uitloggen") {
    const authService = getInstance()
    await authService.logout()

    const returnUrl = store.state.config.returnUrl
    store.dispatch("resetState")

    if (returnUrl) {
      location.href = returnUrl
      return next()
    }
    return next('/login')
  }
  return false
}

// Default page handling
const handleDefaultPage = async (to, next) => {
  if (to.path === '/') {
    if (store.state.config.startpage) {
      return next(store.state.config.startpage)
    }

    const waitTime = 2000
    const timeoutPromise = new Promise(resolve => 
      setTimeout(resolve, waitTime, '/schedule')
    )
    
    const startpagePromise = new Promise(resolve => {
      const unsubscribe = store.watch(
        (state) => state.config.startpage,
        (startpage) => {
          if (startpage) {
            unsubscribe()
            resolve(startpage)
          }
        }
      )
    })

    return next(await Promise.race([timeoutPromise, startpagePromise]))
  }
  return false
}

// Main router guard
router.beforeEach(async (to, from, next) => {
  // Handle locale first
  const localeResult = handleLocaleChange(to, next)
  if (localeResult) return localeResult

  // Track page view
  handlePageTracking(to)

  // Handle logout
  const logoutResult = await handleLogout(to, next)
  if (logoutResult) return logoutResult

  // Handle default page
  const defaultPageResult = await handleDefaultPage(to, next)
  if (defaultPageResult) return defaultPageResult

  next()
})

export default router
