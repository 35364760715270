import i18n from '@/plugins/i18n'

/**
 * Gets a translated string from an object containing language keys
 * Uses the same fallback logic as Vue I18n:
 * 1. Try current language
 * 2. Try Dutch (nl) as first fallback
 * 3. Try English (en) as second fallback
 * 4. Return fallback value if provided, otherwise empty string
 * 
 * @param {Object} obj - Object containing language keys (e.g. {nl: "Hallo", en: "Hello"})
 * @param {string} [fallback=''] - Optional fallback value if no translation is found
 * @returns {string} The translated string or fallback value
 * 
 * @example
 * const label = {nl: "Naam", en: "Name", de: "Name"}
 * getTranslatedString(label) // Returns "Name" when current locale is 'de'
 * getTranslatedString(label, "Unknown") // Returns "Unknown" when no translation found
 */
export function getTranslatedString(obj, fallback = '') {
    if (!obj) {
        return fallback;
    }

    // First try current language
    if (obj[i18n.locale]) {
        return obj[i18n.locale];
    }

    // Then try fallback locales in order
    if (obj['nl']) {
        return obj['nl'];
    }
    if (obj['en']) {
        return obj['en'];
    }

    return fallback;
} 