import Vuex from "vuex"
import Vue from "vue"
import createPersistedState from "vuex-persistedstate";
import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'
import { getConfig, getCustomFields, getContactInfo, getOptins, updateContactInfo, updateOptins } from '../services/ContactService';
import { getCredit, getLoyalty, getSchedule, getHistory } from '../services/OrderService';
import { getWaitinglist } from '../services/WaitinglistService';

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    shortname: null,
    config: {
      shortname: "",
      accountname: "",
      image: "",
      class: "",
      credit: false,
      loyalty: false,
      footer: "",
      salesUrl: null,
      nav: {
        myTickets: {
          nl: "Mijn tickets",
          en: "My tickets",
          de: "Meine Tickets",
          fr: "Mes billets",
        },
        myWaitinglist: {
          nl: "Mijn wachtlijst",
          en: "My waitinglist",
          de: "Meine Warteliste",
          fr: "Ma liste d'attente",
        },
        personalCredit: {
          nl: "Persoonlijk tegoed",
          en: "Personal credit",
          de: "Persönliches Guthaben",
          fr: "Crédit personnel",
        },
        loyaltyCards: {
          nl: "Voordeelkaarten",
          en: "Loyalty cards",
          de: "Loyalty-Karten",
          fr: "Cartes fidélité",
        },
        personalData: {
          nl: "Persoonlijke gegevens",
          en: "Personal data",
          de: "Persönliche Daten",
          fr: "Données personnelles",
        },
        buyTickets: {
          nl: "Bestel tickets",
          en: "Buy tickets",
          de: "Tickets kaufen",
          fr: "Acheter des billets",
        },
        logOut: {
          nl: "Uitloggen",
          en: "Log out",
          de: "Abmelden",
          fr: "Déconnexion",
        },
      },
      isLoading: false,
      isLoaded: false,
    },
    contact: {
      contact: null,
      addresses: [],
      phonenumbers: [],
    },
    contactconfig: {
      addresstypes: [],
      contacttitles: [],
      countries: [],
      phonenumbertypes: [],
      relationtypes: [],
    },
    customfields: {
      loaded: false,
      fields: [],
    },
    credit: {
      vouchers: [],
    },
    loyalty: {
      vouchers: [],
    },
    optins: {
      loaded: false,
      contactoptins: [],
    },
    schedule: {
      orders: [],
      events: null,
      numberOfTickets: 0,
      totalAmount: 0,
    },
    history: {
      orders: [],
      events: null,
      numberOfTickets: 0,
      totalAmount: 0,
    },
    waitinglist: {
      events: null,
    },
    auth: {
      isAuthenticated: false,
      user: null,
      contactinfo: {
        lastname: "",
        middlename: "",
        firstname: "",
        relationtypes: [],
      }
    },
  }
}

function hasCommonItem(arr1, arr2) {
  return arr1.some(item => {
    return arr2.includes(item);
  });
}

const state = getDefaultState()

const getters = {
  config(state) {
    return state.config;
  },
  fullName(state) {
    const contact = state.auth.contactinfo;
    return [contact.firstname, contact.middlename, contact.lastname]
      .filter(Boolean)
      .join(" ");
  },
  iban(state) {
    const contact = state.auth.contactinfo;
    return contact.iban;
  },
  contact(state) {
    return state.contact;
  },
  schedule(state) {
    return state.schedule;
  },
  history(state) {
    return state.history;
  },
  waitinglist(state) {
    return state.waitinglist;
  },
  credit(state) {
    return state.credit;
  },
  loyalty(state) {
    return state.loyalty;
  },
  optins(state) {
    return state.optins;
  },
  contactLabel: (state) => {
    if (!state.auth.contactinfo?.relationtypes || !state.config.labels) {
      return '';
    }

    const result = state.config.labels.find(item => {
      return hasCommonItem(item.relationtypes, state.auth.contactinfo.relationtypes);
    });

    if (!result) {
      return '';
    }

    const lang = state.config.locale || 'nl';
    return result.label[lang];
  }
}

const mutations = {
  setShortname (state, shortname) {
    state.shortname = shortname
  },
  SET_ACCOUNTCONFIG (state, data) {
    state.config = data
  },
  SET_CONTACTINFO (state, data) {
    state.contact = data
  },
  SET_CONTACTCONFIG (state, data) {
    state.contactconfig = data
  },
  SET_CUSTOMFIELDS (state, data) {
    state.customfields = {
      loaded: true,
      fields: data,
    }
  },
  SET_SCHEDULE (state, data) {
    const schedule = {
      orders: data.orders,
      events: data.events,
      numberOfTickets: data.meta.nbroftickets,
      totalAmount: data.meta.totalamount,
    }
    state.schedule = schedule      
  },
  SET_HISTORY (state, data) {
    const history = {
      orders: data.orders,
      events: data.events,
      numberOfTickets: data.meta.nbroftickets,
      totalAmount: data.meta.totalamount,
    }
    state.history = history      
  },
  SET_WAITINGLIST (state, data) {
    const schedule = {
      events: data.events,
    }
    state.waitinglist = schedule      
  },
  SET_CREDIT (state, data) {
    const credit = {
      vouchers: data.vouchers,
      totalRemaining: data.meta.totalamount - data.meta.totalused,
      totalAmount: data.meta.totalamount,
      totalUsed: data.meta.totalused,
    }
    state.credit = credit 
  },
  SET_LOYALTY (state, data) {
    const loyalty = {
      vouchers: data.vouchers,
    }
    state.loyalty = loyalty
  },
  SET_OPTINS (state, { optins }) {
    state.optins = {
      loaded: true,
      contactoptins: optins,
    }
  },
  RESET_CONTACTINFO (state) {
    const defaultState = getDefaultState()
    state.contact = defaultState.contact
  },
  RESET_SCHEDULE (state) {
    const defaultState = getDefaultState()
    state.schedule = defaultState.schedule
  },
  RESET_HISTORY (state) {
    const defaultState = getDefaultState()
    state.history = defaultState.history
  },
  RESET_WAITINGLIST (state) {
    const defaultState = getDefaultState()
    state.waitinglist = defaultState.waitinglist
  },
  RESET_CREDIT (state) {
    const defaultState = getDefaultState()
    state.credit = defaultState.credit
  },
  RESET_LOYALTY (state) {
    const defaultState = getDefaultState()
    state.loyalty = defaultState.loyalty
  },
  RESET_OPTINS (state) {
    const defaultState = getDefaultState()
    state.optins = defaultState.optins
  },
  RESET_STATE (state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118    
    Object.assign(state, getDefaultState())
  },
  SET_AUTH_INFO(state, { isAuthenticated, user, contactinfo }) {
    state.auth.isAuthenticated = isAuthenticated;
    state.auth.user = user;
    state.auth.contactinfo = contactinfo;
  },
  SET_AUTH_CONTACTINFO(state, contactinfo) {
    state.auth.contactinfo = contactinfo;
  },
}

const actions = {
  async setContactInfo({ commit }) {
    const data = await getContactInfo()
    commit('SET_CONTACTINFO', data)
  },
  async setContactConfig({ commit }) {
    const data = await getConfig()
    commit('SET_CONTACTCONFIG', data)
  },
  async setCustomFields({ commit }) {
    const data = await getCustomFields()
    commit('SET_CUSTOMFIELDS', data)
  },
  async setSchedule({ commit }) {
    const data = await getSchedule()
    commit('SET_SCHEDULE', data)
  },
  async setHistory({ commit }) {
    const data = await getHistory()
    commit('SET_HISTORY', data)    
  },
  async setWaitinglist({ commit }) {
    const data = await getWaitinglist()
    commit('SET_WAITINGLIST', data)
  },
  async setCredit({ commit }) {
    const data = await getCredit()
    commit('SET_CREDIT', data)
  },
  async setLoyalty({ commit }) {
    const data = await getLoyalty()
    commit('SET_LOYALTY', data)
  },
  async setOptins({ commit }) {
    const data = await getOptins()
    commit('SET_OPTINS', data)
  },  
  async updateContactInfo({ commit }, input) {
    const data = await updateContactInfo(input)
    commit('SET_CONTACTINFO', data)
  },
  async updateOptins({ commit }, input) {
    const data = await updateOptins(input)
    commit('SET_OPTINS', data)
  },
  resetContactInfo({ commit }) {
    commit('RESET_CONTACTINFO')
  },
  resetSchedule({ commit }) {
    commit('RESET_SCHEDULE')
  },
  resetHistory({ commit }) {
    commit('RESET_HISTORY')
  },
  resetWaitinglist({ commit }) {
    commit('RESET_WAITINGLIST')
  },
  resetCredit({ commit }) {
    commit('RESET_CREDIT')
  },
  resetLoyalty({ commit }) {
    commit('RESET_LOYALTY')
  },
  resetOptins({ commit }) {
    commit('RESET_OPTINS')
  },
  resetState({ commit }) {
    commit('RESET_STATE')
  },
  async setAccountConfig({ commit }, config) {
    commit('SET_ACCOUNTCONFIG', config)
    // Wacht een tick om zeker te zijn dat de state is bijgewerkt
    await Vue.nextTick()
  },
  setAuthInfo({ commit }, authInfo) {
    commit('SET_AUTH_INFO', authInfo);
  },
  setAuthContactInfo({ commit }, contactinfo) {
    commit('SET_AUTH_CONTACTINFO', contactinfo);
  }
}

const logrocketPlugin = createPlugin(LogRocket, function(mutation) {
  if (mutation.type === 'SET_CONTACTINFO') {
    return {
      ...mutation,
      payload: {
        contact: {
          ...mutation.payload.contact,
          firstname: undefined,
          middlename: undefined,
          lastname: undefined,
          birthdate: undefined,
        },
        addresses: undefined,
        phonenumbers: undefined,
      },
    };
  }
  return mutation;
})
const store = new Vuex.Store({
  modules: {},
  state,
  getters,
  mutations,
  actions,
  plugins: [createPersistedState(), logrocketPlugin],
})

export default store